import React, { useEffect } from "react"

export default function GoogleAdSense() {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({})
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-6413847819140925"
      data-ad-slot="3502747653"
      data-ad-format="horizontal"
      data-full-width-responsive="true"
    />
  )
}
