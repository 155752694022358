import { MDXProvider } from "@mdx-js/react"
import dayjs from "dayjs"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import AmazonLink from "../components/amazon_link"
import Bio from "../components/bio"
import Date from "../components/date"
import EyeCatch from "../components/eye_catch"
import FeatureTags from "../components/feature_tags"
import GoogleAdSense from "../components/google_ad_sense"
import Layout from "../components/layout"
import PreviousAndNextPosts from "../components/previous_and_next_posts"
import RichLink from "../components/rich_link"
import Section from "../components/section"
import Seo from "../components/seo"
import TagLink from "../components/tag_link"

const shortcodes = { AmazonLink, RichLink }

class Posts extends React.Component {
  render() {
    const post = this.props.data.mdx
    const { previous, next } = this.props.pageContext

    return (
      <Layout>
        <Seo title={post.frontmatter.title} description={post.excerpt} />
        <Section>
          <EyeCatch tags={post.frontmatter.tags} size="large"></EyeCatch>
          <h1>{post.frontmatter.title}</h1>
          <Date date={dayjs(post.frontmatter.date).format("YYYY-MM-DD")} />
          {post.frontmatter.tags.map((tag) => {
            return <TagLink>{tag}</TagLink>
          })}
        </Section>
        <Section noPaddingTop>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </Section>
        <Section noPaddingTop>
          <Bio />
        </Section>
        <Section noPaddingTop>
          <GoogleAdSense />
        </Section>
        <Section noPaddingTop>
          <PreviousAndNextPosts
            previous={previous}
            next={next}
          ></PreviousAndNextPosts>
        </Section>
        <Section noPaddingTop>
          <FeatureTags />
        </Section>
      </Layout>
    )
  }
}

export default Posts

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(truncate: true, pruneLength: 160)
      body
      frontmatter {
        title
        date
        tags
      }
    }
  }
`
