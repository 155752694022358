import { Grid } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import EyeCatch from "./eye_catch"
import { titleStyle } from "./previous_and_next_posts.module.sass"

const PreviousAndNextPosts = ({ previous, next }) => (
  <Grid
    container
    spacing={4}
    direction="row"
    justify={previous ? "space-between" : "flex-end"}
    alignItems="flex-start"
  >
    {previous && (
      <Grid item xs={12} sm={6}>
        <Link to={previous.fields.slug} rel="prev">
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <EyeCatch tags={previous.frontmatter.tags} size="small" />
            </Grid>
            <Grid item xs>
              <p className={titleStyle}>{previous.frontmatter.title}</p>
            </Grid>
          </Grid>
        </Link>
      </Grid>
    )}
    {next && (
      <Grid item xs={12} sm={6}>
        <Link to={next.fields.slug} rel="next">
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <p className={titleStyle}>{next.frontmatter.title}</p>
            </Grid>
            <Grid item>
              <EyeCatch tags={next.frontmatter.tags} />
            </Grid>
          </Grid>
        </Link>
      </Grid>
    )}
  </Grid>
)

export default PreviousAndNextPosts
