import { Card, CardContent } from "@material-ui/core"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  card,
  content,
  description,
  domain,
  image,
  title,
} from "./rich_link.module.sass"

const RichLink = ({ url }) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { fields: { ogpContent: { eq: "true" } } }) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
              id
              fields {
                url
                title
                description
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const targetEdge = data.allFile.edges.find(
        (edge) => edge.node.fields.url === url
      )

      if (targetEdge && targetEdge.node) {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Card className={card} variant="outlined">
              <CardContent className={content}>
                <p className={title}>{targetEdge.node.fields.title}</p>
                <p className={description}>
                  {targetEdge.node.fields.description}
                </p>
                <p className={domain}>
                  {new URL(targetEdge.node.fields.url).host}
                </p>
              </CardContent>
              {(() => {
                if (targetEdge.node.childImageSharp) {
                  return (
                    <GatsbyImage
                      image={targetEdge.node.childImageSharp.gatsbyImageData}
                      className={image}
                    />
                  )
                }
              })()}
            </Card>
          </a>
        )
      } else {
        return (
          <p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </p>
        )
      }
    }}
  />
)

export default RichLink
