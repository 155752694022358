import { Card, CardContent } from "@material-ui/core"
import dayjs from "dayjs"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  card,
  content,
  description,
  domain,
  fetchedAt,
  image,
  price,
  title,
} from "./amazon_link.module.sass"

const AmazonLink = ({ code }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { fields: { amazonContent: { eq: "true" } } }) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
              id
              fields {
                code
                title
                price
                fetchedAt
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const targetEdge = data.allFile.edges.find(
        (edge) => edge.node.fields.code === code
      )
      const url = `https://www.amazon.co.jp/dp/${code}/?tag=kikunantoka-com-22`

      if (targetEdge && targetEdge.node) {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Card className={card} variant="outlined">
              <CardContent className={content}>
                <p className={title}>{targetEdge.node.fields.title}</p>
                <p className={description}>
                  <span className={price}>{targetEdge.node.fields.price}</span>
                  <span className={fetchedAt}>
                    (
                    {dayjs(targetEdge.node.fields.fetchedAt).format(
                      "YYYY年MM月DD日時点"
                    )}
                    )
                  </span>
                </p>
                <p className={domain}>amazon.co.jp</p>
              </CardContent>
              {(() => {
                if (targetEdge.node.childImageSharp) {
                  return (
                    <GatsbyImage
                      image={targetEdge.node.childImageSharp.gatsbyImageData}
                      className={image}
                    />
                  )
                }
              })()}
            </Card>
          </a>
        )
      } else {
        return (
          <p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </p>
        )
      }
    }}
  />
)

export default AmazonLink
